import s from './OfferingList.st.css';
import { OfferingViewModelFactory } from '../../../domain/offering-view-model-factory';
import OfferingView from '../../OfferingView/OfferingView';
import { translate, TransProps } from 'react-i18next';
import {
  RunningEnvironmentProps,
  withRunningEnvironmentContext,
} from '../../context/running-environment-context';
import * as React from 'react';
import { Grid } from 'wix-ui-tpa/Grid';
import { SIDE_PADDING } from '../constants';
import { OfferingListWidgetDisplayOptions } from '../../../display-options/offering-list-widget-display-options';
import { OfferingDomain } from '../../../domain/offering-domain';
import { IMultiOfferingsViewModel } from '../../../domain/multi-offerings-view-model-factory';
import {
  ExperimentsProps,
  withExperimentsContext,
} from '../../../../Shared/context/experiments-context';
import { TProp } from '../../../../Shared/context/trans-prop';
import { DEFAULT_IMAGE_CONTAINER } from '../../OfferingView/OfferingView.const';
import { OfferingListLayoutOptions } from '../../../../Shared/appKeys/SettingsKeys';
import { isNonGridUserAgent } from '../../../adapters/compatibility/agent-version';

interface OfferingGridProps
  extends RunningEnvironmentProps,
    TransProps,
    TProp,
    ExperimentsProps {
  displayOptions: OfferingListWidgetDisplayOptions;
  offeringsDomain: OfferingDomain[];
  multiOfferingsViewModel: IMultiOfferingsViewModel;
}

interface OfferingImageDimensions {
  height: number;
  width: number;
}

interface OfferingViewState {
  imageDimensions: OfferingImageDimensions;
  dimensionsUpdateAttempts: number;
}

export class OfferingGridComponent extends React.PureComponent<
  OfferingGridProps,
  OfferingViewState
> {
  static displayName = 'OfferingGrid';
  private readonly imageRef;

  constructor(props) {
    super(props);
    this.imageRef = React.createRef();
    this.state = {
      imageDimensions: DEFAULT_IMAGE_CONTAINER,
      dimensionsUpdateAttempts: 0,
    };
    this.updateImageDimensions = this.updateImageDimensions.bind(this);
  }

  private get width(): number {
    const { width } = this.props.runningEnvironment.dimensions;

    return width ? width - SIDE_PADDING * 2 : 0;
  }

  private get items() {
    const {
      t,
      displayOptions,
      offeringsDomain,
      runningEnvironment,
      experiments,
    } = this.props;
    const { isMobile, isRTL, locale } = runningEnvironment;

    let isVerticalAligned = true;
    const offeringViewModels = offeringsDomain.map((offeringDomain) => {
      const offeringViewModel = OfferingViewModelFactory.createOfferingViewModel(
        offeringDomain,
        displayOptions.offeringDisplayOptions,
        t,
        isRTL,
        isMobile,
        experiments,
        locale,
      );
      isVerticalAligned =
        isVerticalAligned && offeringViewModel.verticalAlignInfoContent;

      return offeringViewModel;
    });
    const { isImageDimensionsCalcByParent } = experiments;
    const isImageVisible = this.isImageVisible();

    return offeringViewModels.map((offeringViewModel, index) => {
      offeringViewModel.verticalAlignInfoContent = isVerticalAligned;
      return (
        <Grid.Item key={`offering-${offeringViewModel.id}`}>
          <OfferingView
            isImageVisible={isImageVisible}
            offeringViewModel={offeringViewModel}
            offeringViewImageRef={index === 0 ? this.imageRef : null}
            isImageDimensionsCalculatedByParent={isImageDimensionsCalcByParent}
            imageDimensions={this.state.imageDimensions}
          />
        </Grid.Item>
      );
    });
  }

  private isImageVisible() {
    const { displayOptions, runningEnvironment } = this.props;
    const { isSSR, isMobile } = runningEnvironment;
    const shouldHideImageDueToUserAgentCompatibility =
      !isSSR &&
      !isMobile &&
      displayOptions.offeringDisplayOptions.layout ===
        OfferingListLayoutOptions.GRID &&
      isNonGridUserAgent();
    return (
      !shouldHideImageDueToUserAgentCompatibility &&
      displayOptions.offeringDisplayOptions.isOfferingImageVisible
    );
  }

  private updateImageDimensions(): void {
    if (this.isImageVisible()) {
      const { clientWidth: width, clientHeight: height } = this.imageRef
        ?.current || { width: 0, height: 0 };

      if (height !== this.state.imageDimensions.height) {
        if (height === 0 && this.state.dimensionsUpdateAttempts < 2) {
          setTimeout(this.updateImageDimensions, 200);
          this.setState({
            dimensionsUpdateAttempts: this.state.dimensionsUpdateAttempts + 1,
          });
        } else {
          this.setState({ imageDimensions: { width, height } });
        }
      }
    }
  }

  componentDidMount() {
    this.updateImageDimensions();
  }

  componentDidUpdate() {
    this.updateImageDimensions();
  }

  render() {
    const { multiOfferingsViewModel, runningEnvironment } = this.props;

    const {
      spaceBetweenOfferings,
      maxItemsPerRow,
      dividerWidth,
      cardMinWidth,
      cardMaxWidth,
      withDivider,
      uniformRowHeight,
    } = multiOfferingsViewModel;

    return (
      <Grid
        {...s('root')}
        width={this.width}
        maxColumns={maxItemsPerRow}
        minColumnWidth={cardMinWidth * runningEnvironment.scale}
        maxColumnWidth={cardMaxWidth}
        dividerWidth={dividerWidth}
        showRowDivider={withDivider}
        rowGap={spaceBetweenOfferings}
        columnGap={spaceBetweenOfferings}
        uniformRowHeight={uniformRowHeight}
      >
        {this.items}
      </Grid>
    );
  }
}

export const OfferingList = withRunningEnvironmentContext(
  withExperimentsContext(
    translate(null, { wait: true })(OfferingGridComponent),
  ),
);
